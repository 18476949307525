// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$FdsWeb-primary: mat.define-palette(mat.$indigo-palette);
$FdsWeb-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$FdsWeb-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$FdsWeb-theme: mat.define-light-theme(
  (
    color: (
      primary: $FdsWeb-primary,
      accent: $FdsWeb-accent,
      warn: $FdsWeb-warn,
    ),
  )
);

@font-face {
  font-family: lucon;
  src: url(^assets/fonts/LUCON.ttf) format('truetype');
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($FdsWeb-theme);

@import "node_modules/govuk-frontend/dist/govuk/all";


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "GDS Transport", arial, sans-serif;
}

.capitalize {
  text-transform: capitalize;
}

.datepicker td.day:hover {
  background-color: #ffdd00 !important;
}

.app-width-container {
  @include govuk-width-container(1100px);
}